@charset "UTF-8";
/**
 * Enhanced
 * Bootstrap the rest of the styles on cut the mustard
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Style mixins
 *
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
.u-h1, .c-hero__title, h1 {
  font-size: 2rem;
  font-weight: 700;
}

.u-h2, .c-card--major .c-card__title, h2 {
  font-size: 1.5rem;
  font-weight: 700;
}

.u-h3, .c-card__title, h3 {
  font-size: 1.25rem;
  font-weight: 700;
}

.u-h4, h6, h5, h4 {
  font-size: 1.125rem;
  font-weight: 700;
}

/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 *  Button settings
 *  Nature
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Global
 * Main brand settings
 */
.u-h1, .c-hero__title, h1 {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 2rem;
  font-size: min(max(1.5rem, 4vw), 2rem);
  letter-spacing: -0.0390625rem;
  letter-spacing: min(max(-0.011715625rem, 4vw), -0.0390625rem);
  line-height: 2.25rem;
  line-height: min(max(1.6rem, 4vw), 2.25rem);
}

.u-h2, .c-card--major .c-card__title, h2 {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3.5vw), 1.5rem);
  letter-spacing: -0.011715625rem;
  line-height: 1.6rem;
  line-height: min(max(1.4rem, 3.5vw), 1.6rem);
}

.u-h3, .c-card__title, h3 {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 3.0vw), 1.25rem);
  letter-spacing: -0.011715625rem;
  line-height: 1.4rem;
}

.u-h4, h6, h5, h4 {
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  font-size: min(max(1rem, 2.5vw), 1.125rem);
  letter-spacing: -0.011715625rem;
  line-height: 1.4rem;
}

/**
 * Forms
 * Default form styles
 */
fieldset {
  border: 0;
}

input:focus,
button:focus,
select:focus {
  outline: 3px solid #fece3e;
  will-change: transform;
}

button:active {
  outline: 0;
}

input + label {
  padding-left: 0.5em;
}

/*
 * Layout
 * Universal layout styles
 */
html {
  height: 100%;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.76;
  color: #222222;
  background: #eeeeee;
}

figure {
  margin: 0;
}

abbr[title] {
  text-decoration: none;
}

[contenteditable]:focus,
[tabindex="0"]:focus {
  outline: 3px solid #fece3e;
  will-change: transform;
}

/**
 * Links
 * Basic link styles
 */
a {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  vertical-align: baseline;
  color: #006699;
}
a:focus {
  outline: 3px solid #fece3e;
  will-change: transform;
}

a:active {
  outline: 0;
}

/**
 * Lists
 * Default list styles
 */
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

/**
 * Tables
 * Default table styles
 */
table {
  width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

th,
td {
  padding: 4px 7px;
  border-right: 1px solid rgb(212.5, 212.5, 212.5);
  text-align: left;
  line-height: 1.15;
}

th {
  vertical-align: top;
}

td {
  vertical-align: middle;
}

th[valign=top] {
  vertical-align: top;
}

th[valign=middle] {
  vertical-align: middle;
}

thead tr {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
}

tbody tr {
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
}

tbody tr:first-child {
  border-top: 0;
}

th {
  background: #eeeeee;
}

tfoot tr {
  border-top: 2px solid rgb(212.5, 212.5, 212.5);
}

tfoot td {
  font-weight: bold;
}

/**
 * Typography
 * Set base font size
 */
html {
  font-size: 100%;
}

body {
  font-size: 1.125rem;
}

/**
 * Typographic
 * Standard text and heading formatting
 */
/*
 * Default spacing
 * default margin 28px to match line height
 * this is used for mainting vertical rhythm on the page
 */
/*
 * Headings
 */
p:empty {
  display: none;
}

html {
  box-sizing: border-box;
}

html *,
html *::before,
html *::after {
  box-sizing: inherit;
}

body {
  background-color: white;
}

/**
 * Settings
 */
/**
 * @springernature/global-ad
 * Default skin settings
 *
 */
/**
 * @springernature/global-author-list
 * Nature skin settings
 *
 */
/**
 * @springernature/global-author-list
 * Default skin settings
 *
 */
/**
 * @springernature/global-banner
 * Nature skin settings
 */
/**
 * @springernature/global-banner
 * Default skin settings
 *
 */
/**
 * Facet
 * Nature branding for component
 */
/**
 * Facet
 * Default branding for component
 */
/**
 * @springernature/global-footer
 * Default skin settings
 *
 */
/**
 * @springernature/global-layout-with-sidebar
 * Nature skin settings
 */
/**
 * @springernature/global-layout-with-sidebar
 * Default skin settings
 *
 */
/**
 * List
 * Nature branding for component
 */
/**
 * @springernature/global-menu
 * Default skin settings
 *
 */
/**
 * Meta
 * Nature branding for component
 */
/**
 * Meta
 * Default branding for component
 */
/**
 * @springernature/global-pill
 * Nature skin settings
 */
/**
 * @springernature/global-popup
 * Nature skin settings
 */
/**
 * Popup
 * Default branding for component
 */
/**
 * @springernature/global-skip-link
 * Default skin settings
 *
 */
/**
 * @springernature/global-skip-link
 * Default skin settings
 *
 */
/**
 * Status Message
 * Default branding for component
 */
/**
 * Components
 */
.c-ad {
  text-align: center;
}
@media only screen and (min-width: 320px) {
  .c-ad {
    padding: 8px;
  }
}

.c-ad--728x90,
.c-ad--970x90 {
  display: none;
  background-color: #ccc;
}
.c-ad--728x90 .c-ad__inner,
.c-ad--970x90 .c-ad__inner {
  min-height: calc(1.5em + 4px + 90px);
}
.c-ad--728x90 iframe,
.c-ad--970x90 iframe {
  max-width: 970px;
  height: 90px;
}

@media only screen and (min-width: 768px) {
  .js .c-ad--728x90 {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .js .u-show-following-ad + .c-ad--728x90 {
    display: block;
  }
}

.c-ad--160x600 {
  display: none;
  padding: 0;
  text-align: left;
}
.c-ad--160x600 iframe {
  width: 160px;
  height: 600px;
}

@media only screen and (min-width: 768px) {
  .js .c-ad--160x600 {
    display: block;
  }
}

.c-ad--300x250 {
  display: none;
  background-color: transparent;
  padding: 0;
}
.c-ad--300x250 iframe {
  width: 300px;
  height: 250px;
}
.c-ad--300x250 .c-ad__inner {
  min-height: calc(1.5em + 4px + 250px);
}

@media only screen and (min-width: 320px) {
  .js .c-ad--300x250 {
    display: block;
  }
}

.c-ad iframe {
  border: none;
  overflow: auto;
  vertical-align: top;
}

.c-ad__label {
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  margin-bottom: 4px;
  color: #333;
  line-height: 1.5;
}

.c-author-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: inherit;
  color: rgb(110.5, 110.5, 110.5);
  line-height: inherit;
}
.c-author-list > li {
  display: inline;
}
.c-author-list > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.c-author-list > li:not(:only-child):last-child::before {
  content: " & ";
}

.c-author-list--compact {
  line-height: 1.4;
  font-size: 0.875rem;
}

.c-author-list--truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.js .c-author-list {
  display: inline;
}
.js .c-author-list__hide {
  display: none;
  visibility: hidden;
}
.js .c-author-list__hide:first-child + * {
  margin-block-start: 0;
}

/**
 * @springernature/global-banner
 * A container with text and link
 *
 */
.c-banner {
  border-bottom: 0;
  background-color: white;
  padding: 8px 24px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.c-banner--compact {
  font-size: 0.875rem;
  padding: 4px 4px;
}

.c-banner--contrast {
  background-color: rgb(46.75, 46.75, 46.75);
  color: white;
}
.c-banner--contrast .c-banner__link {
  color: white;
}
.c-banner--contrast .c-banner__link:hover, .c-banner--contrast .c-banner__link:visited {
  color: white;
}

.c-banner--marketing {
  background-color: #004b83;
  border-bottom: 0;
  color: white;
}
.c-banner--marketing .c-banner__link {
  color: white;
}
.c-banner--marketing .c-banner__link:hover, .c-banner--marketing .c-banner__link:visited {
  color: white;
}

.c-banner--flush {
  padding-left: 0;
  padding-right: 0;
}

.c-banner__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-banner__container > :only-child {
  margin-left: auto;
  margin-right: auto;
}

.c-banner__container--center {
  justify-content: center;
}

.c-banner__item {
  flex: 0 1 auto;
  margin: 0;
}

.c-banner__link {
  text-decoration: underline;
  color: rgb(97.75, 97.75, 97.75);
}
.c-banner__link:visited, .c-banner__link:hover {
  color: rgb(97.75, 97.75, 97.75);
}

.c-breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: black;
}
.c-breadcrumbs > li {
  display: inline;
}

.c-breadcrumbs__link {
  color: #666;
}
.c-breadcrumbs__link.visited, .c-breadcrumbs__link:visited, .c-breadcrumbs__link.hover, .c-breadcrumbs__link:hover {
  color: #666;
}

svg.c-breadcrumbs__chevron {
  margin: 4px 4px 0;
  fill: #888888;
  width: 10px;
  height: 10px;
}

@media only screen and (max-width: 539px) {
  .c-breadcrumbs .c-breadcrumbs__item {
    display: none;
  }
}

@media only screen and (max-width: 539px) {
  .c-breadcrumbs .c-breadcrumbs__item:nth-last-child(1),
  .c-breadcrumbs .c-breadcrumbs__item:nth-last-child(2) {
    display: inline;
  }
}

.c-facet {
  background-color: #eeeeee;
  padding: 8px;
  position: relative;
  font-size: 1rem;
  color: black;
}
@media only screen and (min-width: 540px) {
  .c-facet {
    padding: 16px;
  }
}

.c-facet--small {
  font-size: 0.875rem;
}

.c-facet__container {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 540px) {
  .c-facet__container {
    flex-direction: row;
  }
}

.c-facet__label {
  display: block;
  font-weight: 700;
  white-space: nowrap;
  margin-right: 24px;
  margin-bottom: 8px;
}

.c-facet__item {
  margin-bottom: 8px;
  flex: 1 1 100%;
}
@media only screen and (min-width: 540px) {
  .c-facet__item {
    margin-right: 4px;
  }
  .c-facet__item:last-of-type {
    margin-right: 8px;
  }
}
@media only screen and (min-width: 768px) {
  .c-facet__item {
    margin-right: 16px;
    margin-bottom: 0;
    position: relative;
  }
}

.c-facet__item legend {
  width: 100%;
}

.c-facet__button {
  color: black;
  background: white;
  border: 1px solid black;
  border-radius: 3px;
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;
  height: 34px;
  position: relative;
}
.c-facet__button > svg {
  transition-duration: 0.2s;
}
.c-facet__button.is-open {
  text-decoration: none;
}
.c-facet__button.is-open svg {
  transform: rotate(180deg);
}

.c-facet__button--disabled {
  border-color: rgb(199.75, 199.75, 199.75);
  color: rgb(199.75, 199.75, 199.75);
  cursor: default;
}

.c-facet__selected {
  font-weight: 700;
}

.c-facet__ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  position: absolute;
  left: 8px;
  top: 25%;
  white-space: nowrap;
  width: calc(100% - 28px);
}

.c-facet__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 25%;
  right: 8px;
}

.c-facet__submit {
  background-color: #006699;
  border: 1px solid #006699;
  border-radius: 3px;
  color: white;
  padding: 0.6em 1em;
}
.c-facet__submit:hover {
  background: white;
  color: #006699;
}

.c-facet__clear-all {
  text-decoration: underline;
  flex: 1 1 100%;
  font-size: 0.875rem;
  margin-right: 16px;
  margin-bottom: 16px;
  margin-top: 4px;
}
.js .c-facet__clear-all {
  align-self: flex-end;
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .js .c-facet__clear-all {
    margin-bottom: 8px;
  }
}

.c-footer {
  background-color: #01324b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #fff;
  line-height: 1.15;
}

.c-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
@media print {
  .c-footer__container {
    display: none;
  }
}

.c-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
  line-height: 2;
}
.c-footer__links > li {
  display: inline;
}
.c-footer__links li:not(:last-child) {
  margin-right: 24px;
}

.c-footer__legal {
  color: #fff;
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 0px;
}

.c-footer__link {
  color: inherit;
  white-space: nowrap;
}
.c-footer__link.visited, .c-footer__link:visited {
  color: inherit;
}
.c-footer__link.hover, .c-footer__link:hover {
  color: inherit;
}
.c-footer__link:focus {
  outline: 4px solid #fc0;
}
.c-footer__link > img {
  vertical-align: middle;
}

button.c-footer__link {
  text-decoration: underline;
  border: none;
  padding: 0;
  background: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-footer__grid {
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  .c-footer__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 32px;
  }
}

.c-footer__group {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .c-footer__group {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@supports (display: grid) {
  .c-footer__group {
    padding-right: 0;
    max-width: none;
    margin-bottom: 0;
  }
}

.c-footer__group--separator {
  border-bottom: 2px solid #fff;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.c-footer__heading {
  color: #fff;
  margin-bottom: 16px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.c-footer__heading--visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

.c-footer__item:not(:last-child) {
  margin-bottom: 16px;
}

.c-facet-expander__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-facet-expander__button-container {
  display: none;
  visibility: hidden;
}
.c-facet-expander__button-container:first-child + * {
  margin-block-start: 0;
}

.js .c-facet-expander {
  background-color: black;
  box-sizing: border-box;
  padding: 24px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
}
@media only screen and (min-width: 540px) {
  .js .c-facet-expander {
    transform: translateY(8px);
  }
}
@media only screen and (min-width: 768px) {
  .js .c-facet-expander {
    transform: translateY(16px);
    min-width: 290px;
    width: auto;
  }
}
.js .c-facet-expander__list {
  color: rgb(123.25, 123.25, 123.25);
  font-size: 1rem;
  white-space: nowrap;
}
.js .c-facet-expander__list-item {
  margin-bottom: 4px;
}
.js .c-facet-expander__link {
  color: white;
}
@media only screen and (min-width: 540px) {
  .js .c-facet-expander__link {
    white-space: nowrap;
  }
}
.js .c-facet-expander__link--underline {
  text-decoration: underline;
}
.js .c-facet-expander__button-container {
  display: block;
  visibility: visible;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.js .c-facet-expander__clear-selection {
  text-decoration: underline;
  background: none;
  color: white;
  border: none;
  align-self: center;
}

.l-with-sidebar {
  display: flex;
  flex-wrap: wrap;
}

.l-with-sidebar > * {
  margin: 0;
}

.l-with-sidebar__sidebar {
  flex-grow: 1;
  flex-basis: 454px; /* fallback static value for IE */
  flex-basis: var(--with-sidebar--basis, 454px);
}

.l-with-sidebar > :not(.l-with-sidebar__sidebar) {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 53%; /* fallback static value for IE */
  min-width: var(--with-sidebar--min, 53%);
}

.l-with-sidebar > :first-child {
  padding-right: 64px; /* gap for IE11 (so desktop) */
}

@supports (gap: 1em) {
  .l-with-sidebar > :first-child {
    padding-right: 0;
  }
  .l-with-sidebar {
    gap: var(--with-sidebar--gap, 64px);
  }
}
.c-list-description {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 0;
  width: 100%;
}

.c-list-description__item {
  margin-bottom: 24px;
}
.c-list-description__item:last-child {
  margin-bottom: 0;
}

.c-list-description__term {
  font-weight: 700;
  margin-bottom: 0;
}

.c-list-description__details {
  display: block;
}

.c-list-description__details p {
  display: block;
  margin-bottom: 0;
}
.c-list-description__details p:last-child {
  margin-bottom: 0;
}

.c-list-group {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

.c-list-group--xs .c-list-group__item {
  padding: 4px 0;
}

.c-list-group--sm .c-list-group__item {
  padding: 8px 0;
}

.c-list-group--md .c-list-group__item {
  padding: 16px 0;
}

.c-list-group--lg .c-list-group__item {
  padding: 24px 0;
}

.c-list-group--bordered .c-list-group__item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.c-list-group--flush .c-list-group__item:first-child {
  padding-top: 0;
}

.c-list-group--flush .c-list-group__item:last-child {
  padding-bottom: 0;
}

/**
 * Default menu
 *
 */
.c-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  display: flex;
  line-height: 1.3;
}

.c-menu__item:not(:last-child) {
  margin-right: 16px;
}

.c-menu__link {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
.c-menu__link:visited, .c-menu__link:active {
  color: inherit;
}
.c-menu__link:hover {
  text-decoration: underline;
}

/**
 * Small menu
 *
 */
.c-menu--small {
  font-size: 0.875rem;
}

/**
 * Vertical menu
 *
 */
.c-menu--vertical {
  flex-direction: column;
}

.c-menu--vertical .c-menu__item:not(:last-child) {
  margin-bottom: 16px;
  margin-left: 0;
}

/**
 * Keyline menu
 *
 */
.c-menu--keyline .c-menu__item:not(:last-child) {
  margin-right: 16px;
  padding-right: 16px;
  border-right: 1px solid #eeeeee;
}

/**
 * Vertical Keyline menu
 *
 */
.c-menu--vertical.c-menu--keyline .c-menu__item:not(:last-child) {
  margin: 0 0 16px;
  padding: 0 0 16px;
  border-right: none;
  border-bottom: 1px solid #eeeeee;
}

/**
 * Padded menu
 *
 */
.c-menu--padded .c-menu__item:not(:last-child),
.c-menu--padded.c-menu--keyline .c-menu__item:not(:last-child) {
  margin: 0;
  padding: 0;
}

.c-menu--padded .c-menu__link {
  padding: 16px;
  display: block;
}
.c-menu--padded .c-menu__link:hover {
  background-color: #eeeeee;
}

/**
 * Vertical Padded menu
 *
 */
.c-menu--vertical.c-menu--padded .c-menu__item:not(:last-child) {
  margin: 0;
  padding: 0;
}

.c-menu--inherit .c-menu__link {
  color: inherit;
}
.c-menu--inherit .c-menu__link.visited, .c-menu--inherit .c-menu__link:visited {
  color: inherit;
}
.c-menu--inherit .c-menu__link.hover, .c-menu--inherit .c-menu__link:hover {
  color: inherit;
}

.c-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  color: inherit;
  line-height: 1.4;
}

.c-meta--large {
  font-size: 1rem;
}
.c-meta--large .c-meta__item {
  margin-bottom: 8px;
}

.c-meta__item {
  display: inline-block;
  margin-bottom: 4px;
}
.c-meta__item:not(:last-child) {
  border-right: 1px solid rgb(212.5, 212.5, 212.5);
  padding-right: 4px;
  margin-right: 4px;
}

@media only screen and (max-width: 539px) {
  .c-meta__item--block-sm-max {
    display: block;
  }
  .c-meta__item--block-sm-max:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .c-meta__item--block-at-lg {
    display: block;
  }
  .c-meta__item--block-at-lg:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

.c-meta__type {
  text-transform: none;
  font-weight: 700;
}

.c-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.875rem;
  padding: 16px;
}
@media only screen and (min-width: 540px) {
  .c-pagination {
    justify-content: center;
  }
}

.c-pagination__item {
  margin-right: 16px;
  margin-bottom: 8px;
}
.c-pagination__item:last-child {
  margin-right: 0;
}

.c-pagination__link {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #006699;
  background-color: transparent;
  background-image: none;
  border: 1px solid #006699;
  min-width: 30px;
  text-align: center;
}
.c-pagination__link svg {
  fill: currentColor;
}
.c-pagination__link:visited {
  color: #006699;
}
.c-pagination__link:hover {
  border: 1px solid #006699;
}
.c-pagination__link:focus {
  border: 1px solid #006699;
}
.c-pagination__link:hover, .c-pagination__link:focus {
  color: white;
  background-color: #006699;
  background-image: none;
}
.c-pagination__link:hover svg path, .c-pagination__link:focus svg path {
  fill: white;
}

.c-pagination__link--disabled {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #006699;
  background-color: transparent;
  background-image: none;
  border: 1px solid #006699;
  color: #222222;
  background-color: transparent;
  background-image: none;
  border: 1px solid #eeeeee;
  opacity: 0.7;
  cursor: default;
}
.c-pagination__link--disabled svg {
  fill: currentColor;
}
.c-pagination__link--disabled:visited {
  color: #006699;
}
.c-pagination__link--disabled:hover {
  border: 1px solid #006699;
}
.c-pagination__link--disabled:focus {
  border: 1px solid #006699;
}
.c-pagination__link--disabled:hover, .c-pagination__link--disabled:focus {
  color: white;
  background-color: #006699;
  background-image: none;
}
.c-pagination__link--disabled:hover svg path, .c-pagination__link--disabled:focus svg path {
  fill: white;
}
.c-pagination__link--disabled svg {
  fill: currentColor;
}
.c-pagination__link--disabled:visited {
  color: #222222;
}
.c-pagination__link--disabled:hover {
  border: 1px solid #eeeeee;
  text-decoration: none;
}
.c-pagination__link--disabled:focus {
  border: 1px solid #eeeeee;
  text-decoration: none;
}
.c-pagination__link--disabled:hover, .c-pagination__link--disabled:focus {
  color: #222222;
  background-color: transparent;
  background-image: none;
}
.c-pagination__link--disabled:hover svg path, .c-pagination__link--disabled:focus svg path {
  fill: #222222;
}

.c-pagination__link--active {
  color: white;
  border-color: #888888;
  background-color: #888888;
  background-image: none;
  cursor: default;
}

.c-pagination__ellipsis {
  background: none;
  border: 0;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}

.c-pagination__icon {
  fill: #bbbbbb;
  width: 16px;
  height: 12px;
}

.c-pagination__icon--active {
  fill: #004b83;
}

.c-pill {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #004b83;
  border-radius: 20px;
  color: #004b83;
  background-color: #ebf6ff;
  font-weight: 700;
  margin-right: 4px;
  margin-bottom: 8px;
}

.c-pill--secondary {
  color: #222222;
  border-color: #222222;
  background-color: #eee;
}

.c-pill--borderless {
  border: none;
}

.js .c-popup {
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  z-index: 100;
  padding: 16px;
  border: 1px solid #222222;
  box-shadow: 0 0 5px 0 rgba(161.5, 161.5, 161.5, 0.1);
  width: auto;
  border-radius: 2px;
  background-color: #fff;
}
.js .c-popup__close {
  position: absolute;
  display: block;
  top: 16px;
  right: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.29679575 12.2772478c-.39658757.3965876-.39438847 1.0328109-.00062148 1.4265779.39651227.3965123 1.03246768.3934888 1.42657791-.0006214l4.27724782-4.27724787 4.2772478 4.27724787c.3965876.3965875 1.0328109.3943884 1.4265779.0006214.3965123-.3965122.3934888-1.0324677-.0006214-1.4265779l-4.27724787-4.2772478 4.27724787-4.27724782c.3965875-.39658757.3943884-1.03281091.0006214-1.42657791-.3965122-.39651226-1.0324677-.39348875-1.4265779.00062148l-4.2772478 4.27724782-4.27724782-4.27724782c-.39658757-.39658757-1.03281091-.39438847-1.42657791-.00062148-.39651226.39651227-.39348875 1.03246768.00062148 1.42657791l4.27724782 4.27724782z' fill='%23666' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat;
  border: none;
  padding-right: 16px;
}
.js .c-popup__close-text {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.js .c-popup__arrow {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-top: 1px solid #222222;
  border-left: 1px solid #222222;
}
.js .c-popup__arrow--above {
  bottom: -11px;
  transform: rotate(225deg);
}
.js .c-popup__arrow--below {
  top: -11px;
  transform: rotate(45deg);
}

/**
 * @springernature/global-skip-link
 * A full width link which is hidden for mouse users
 *
 */
.c-skip-link {
  background: #069;
  color: white;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-align: center;
  padding: 8px;
  position: absolute;
  inset: 0;
  bottom: auto;
  z-index: 9999;
  transform: translateY(-100%);
}
@media (prefers-reduced-motion: reduce) {
  .c-skip-link {
    transition: top 0.3s ease-in-out;
  }
}
@media print {
  .c-skip-link {
    display: none;
  }
}
.c-skip-link:link, .c-skip-link:active, .c-skip-link:visited, .c-skip-link:hover {
  color: white;
}
.c-skip-link:focus {
  transform: translateY(0%);
}

.c-status-message {
  display: flex;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.c-status-message *:last-child {
  margin-bottom: 0px;
}

.c-status-message--boxed {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #eeeeee;
  line-height: 1.4;
  padding: 16px;
}

.c-status-message__heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.c-status-message__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 auto;
  margin-right: 8px;
}

.c-status-message__icon--top {
  align-self: flex-start;
}

.c-status-message--info .c-status-message__icon {
  color: #003f8d;
}

.c-status-message--boxed.c-status-message--info {
  border-bottom: 4px solid #003f8d;
}

.c-status-message--error .c-status-message__icon {
  color: #c40606;
}

.c-status-message--boxed.c-status-message--error {
  border-bottom: 4px solid #c40606;
}

.c-status-message--success .c-status-message__icon {
  color: #00b8b0;
}

.c-status-message--boxed.c-status-message--success {
  border-bottom: 4px solid #00b8b0;
}

.c-status-message--warning .c-status-message__icon {
  color: #edbc53;
}

.c-status-message--boxed.c-status-message--warning {
  border-bottom: 4px solid #edbc53;
}

.c-header {
  background-color: white;
  border-bottom: 5px solid black;
  font-size: 1rem;
  margin-bottom: 16px;
  line-height: 1.4;
}

.c-header__row {
  position: relative;
  padding: 0;
}
.c-header__row:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}

.c-header__split {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-header__logo-container {
  flex: 1 1 0;
  margin: 8px 24px 8px 0;
  line-height: 0;
}

.c-header__logo {
  transform: translateZ(0);
}
.c-header__logo img {
  max-height: 32px;
}

.c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.c-header__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 1 auto;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 -8px;
}
@media print {
  .c-header__menu {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .c-header__menu--hide-lg-max {
    display: none;
    visibility: hidden;
  }
  .c-header__menu--hide-lg-max:first-child + * {
    margin-block-start: 0;
  }
}

.c-header__menu--global {
  font-weight: 400;
  justify-content: flex-end;
}
.c-header__menu--global svg {
  display: none;
  visibility: hidden;
}
.c-header__menu--global svg:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 540px) {
  .c-header__menu--global svg {
    display: block;
    visibility: visible;
  }
}

.c-header__menu--journal {
  font-size: 0.875rem;
  margin: 8px 0 8px -8px;
}
@media only screen and (min-width: 540px) {
  .c-header__menu--journal {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 540px) {
  .c-header__menu--journal {
    font-size: 1rem;
  }
}

.c-header__item {
  position: static;
  padding-top: 0;
  padding-bottom: 0;
}

.c-header__item--pipe {
  border-left: 2px solid #eeeeee;
  padding-left: 8px;
}

.c-header__item--padding {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media only screen and (min-width: 540px) {
  .c-header__item--dropdown-menu {
    position: relative;
  }
}

@media only screen and (min-width: 1024px) {
  .c-header__item--hide-lg {
    display: none;
    visibility: hidden;
  }
  .c-header__item--hide-lg:first-child + * {
    margin-block-start: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .c-header__item--hide-lg-max {
    display: none;
    visibility: hidden;
  }
  .c-header__item--hide-lg-max:first-child + * {
    margin-block-start: 0;
  }
}

@media only screen and (max-width: 767px) {
  .c-header__item--hide-md-max {
    display: none;
    visibility: hidden;
  }
  .c-header__item--hide-md-max:first-child + * {
    margin-block-start: 0;
  }
}

.c-header__link {
  color: inherit;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}
.c-header__link.visited, .c-header__link:visited {
  color: inherit;
}
.c-header__link.hover, .c-header__link:hover {
  color: inherit;
}
.c-header__link ::first-letter {
  text-transform: capitalize;
}
.c-header__link svg {
  transition-duration: 0.2s;
}

.c-header__show-text {
  display: none;
  visibility: hidden;
}
.c-header__show-text:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 540px) {
  .c-header__show-text {
    display: inline;
    visibility: visible;
  }
}

.c-header__dropdown {
  background-color: black;
  border-bottom: 1px solid rgb(46.75, 46.75, 46.75);
  color: #eeeeee;
  padding: 16px 0;
  font-size: 0.875rem;
  line-height: 1.2;
}
@media print {
  .c-header__dropdown {
    display: none;
  }
}

.c-header__heading {
  display: inline-block;
  line-height: 1.4;
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 400;
  margin-bottom: 8px;
}

.c-header__heading--keyline {
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  padding-top: 16px;
  margin-top: 16px;
  border-color: rgb(46.75, 46.75, 46.75);
  width: 100%;
}

.c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
  margin: 0 -8px;
}

.c-header__flush {
  margin: 0 -8px;
}

.c-header__visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

/**
 Search form
 */
.c-header__search-form {
  margin-bottom: 8px;
}

.c-header__search-layout {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.c-header__search-layout > *:first-child {
  flex: 999 1 auto;
}
.c-header__search-layout > * {
  flex: 1 1 auto;
}

.c-header__search-layout--max-width {
  max-width: 720px;
}

.c-header__search-button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #006699;
  background-color: transparent;
  background-image: none;
  border: 1px solid #006699;
  display: flex;
  width: 100%;
  border: 1px solid white;
  color: white;
  background-color: transparent;
  line-height: 1.15;
  padding: 8px 16px;
}
.c-header__search-button svg {
  fill: currentColor;
}
.c-header__search-button:visited {
  color: #006699;
}
.c-header__search-button:hover {
  border: 1px solid #006699;
}
.c-header__search-button:focus {
  border: 1px solid #006699;
}
.c-header__search-button:hover, .c-header__search-button:focus {
  color: white;
  background-color: #006699;
  background-image: none;
}
.c-header__search-button:hover svg path, .c-header__search-button:focus svg path {
  fill: white;
}
.c-header__search-button:focus, .c-header__search-button:hover {
  background-color: white;
  color: black;
}

.c-header__input,
.c-header__select {
  padding: 8px 16px;
  border: 1px solid;
  border-radius: 3px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.c-header__select {
  display: block;
  width: 100%;
  min-width: 150px;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  margin: 0;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.58578644 3-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l4 4c.39052429.39052429.39052429 1.02368927 0 1.41421356l-4 4c-.39052429.39052429-1.02368927.39052429-1.41421356 0s-.39052429-1.02368927 0-1.41421356z' fill='%23333' fill-rule='evenodd' transform='matrix(0 1 -1 0 11 3)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%;
  /* icon size, then gradient */
  background-size: 1em auto;
  /* Hide arrow icon in IE browsers */
}
.c-header__select::-ms-expand {
  display: none;
  visibility: hidden;
}
.c-header__select::-ms-expand:first-child + * {
  margin-block-start: 0;
}

/**
 Javascript Enhancements
 */
.c-header__link.is-open {
  color: #006699;
  background-color: #eeeeee;
  border-radius: 2px;
  text-decoration: none;
}
.c-header__link.is-open svg {
  fill: currentColor;
}

.c-header__item--dropdown-menu .c-header__link.is-open svg {
  transform: rotate(180deg);
}

@media only screen and (min-width: 540px) {
  .c-header__menu--journal .c-header__item--dropdown-menu:last-child .c-header__dropdown.has-tethered {
    left: auto;
    right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .c-header__menu--journal .c-header__item--dropdown-menu:last-child .c-header__dropdown.has-tethered {
    left: 0;
    right: auto;
  }
}

.c-header__dropdown.has-tethered {
  position: absolute;
  top: 100%;
  transform: translateY(5px);
  z-index: 1;
  left: 0;
  width: 100%;
  border-radius: 0 0 2px 2px;
  border-bottom: 0;
}
@media only screen and (min-width: 540px) {
  .c-header__dropdown.has-tethered {
    transform: translateY(8px);
    width: auto;
  }
}
@media only screen and (min-width: 768px) {
  .c-header__dropdown.has-tethered {
    min-width: 225px;
  }
}

.c-header__dropdown--full-width.has-tethered {
  width: 100%;
  padding: 32px 0 24px;
  transform: none;
}

.has-tethered .c-header__heading--js-hide {
  display: none;
  visibility: hidden;
}
.has-tethered .c-header__heading--js-hide:first-child + * {
  margin-block-start: 0;
}
.has-tethered .c-header__list--js-stack {
  flex-direction: column;
}
.has-tethered .c-header__item--keyline,
.has-tethered .c-header__list ~ .c-header__list .c-header__item:first-child {
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  padding-top: 8px;
  margin-top: 8px;
}

.c-hero {
  position: relative;
  color: white;
  margin-bottom: 16px;
}
@supports (display: flex) {
  @media only screen and (min-width: 768px) {
    .c-hero {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

@media only screen and (max-width: 767px) {
  .c-hero--flush-md-max {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.c-hero__link {
  color: inherit;
}
.c-hero__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.c-hero__link:hover, .c-hero__link:visited {
  color: inherit;
}

.c-hero__copy {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .c-hero__copy {
    flex: 1 1 50%;
  }
}

@media only screen and (min-width: 768px) {
  .c-hero__image {
    flex: 1 1 50%;
    max-width: none;
    position: relative;
    overflow: hidden;
    min-height: 260px;
  }
}
.c-hero__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}

.c-hero__copy {
  background-color: #29303c;
  padding: 16px;
}
@supports (display: flex) {
  @media only screen and (min-width: 768px) {
    .c-hero__copy {
      max-width: calc(40% + 8px);
    }
  }
}
@media only screen and (min-width: 1024px) {
  .c-hero__copy {
    padding: 32px;
  }
}

.c-hero__title {
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .c-hero__title {
    font-size: min(max(1.5rem, 3vw), 2rem);
    line-height: min(max(1.6rem, 3vw), 2.25rem);
  }
}

.c-hero__summary {
  line-height: 1.4;
  margin-bottom: 0px;
  font-size: 0.875rem;
}

.c-section-heading {
  border-top: 5px solid black;
  border-bottom: 1px solid black;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 24px;
  line-height: 1.4;
}
.c-section-heading h1,
.c-section-heading h2,
.c-section-heading h3,
.c-section-heading h4,
.c-section-heading h5,
.c-section-heading h6 {
  margin-bottom: 0;
}

.c-section-heading--no-bt {
  border-top: 0;
  padding-top: 0;
}

.c-section-heading__link {
  color: inherit;
  display: flex;
  align-items: center;
}
.c-section-heading__link.visited, .c-section-heading__link:visited {
  color: inherit;
}
.c-section-heading__link.hover, .c-section-heading__link:hover {
  color: inherit;
}

.c-section-heading__icon {
  margin-left: 4px;
}

.c-collections-sub-nav {
  background-color: #eeeeee;
  padding: 16px;
  margin-bottom: 48px;
}
.c-collections-sub-nav a {
  color: black;
}

.c-collections-subnav--active {
  font-weight: 700;
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}

.app-collections .c-list-description__item {
  margin-bottom: 24px;
  margin-right: 16px;
  padding-right: 16px;
  border-right: 1px solid rgb(212.5, 212.5, 212.5);
}
@media only screen and (min-width: 1024px) {
  .app-collections .c-list-description__item {
    margin-right: 32px;
    padding-right: 32px;
  }
}
.app-collections .c-list-description__item:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border-right: none;
}
.app-collections .c-list-description__item:last-child:not(:only-child) {
  margin-bottom: 0;
}

.app-collections.c-pill {
  font-size: 0.75rem;
}

.app-sponsor {
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  padding-top: 48px;
  margin-top: 48px;
}

.app-sponsor__text {
  font-size: 0.875rem;
  line-height: 1.2;
}

.app-sponsor__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 16px;
  font-size: 0.875rem;
  margin-bottom: 8px;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.app-sponsor__list > li {
  display: inline;
}
@media only screen and (min-width: 1024px) {
  .app-sponsor__list {
    flex-wrap: initial;
  }
}

.app-sponsor__list li {
  display: flex;
  flex-direction: column;
  flex-basis: calc(33.3333333333vw - 32px);
}
.app-sponsor__list li > *:only-child {
  margin-top: auto;
}
@media only screen and (min-width: 1024px) {
  .app-sponsor__list li {
    flex-basis: initial;
  }
}

.app-sponsor__list > li:nth-of-type(1n + 4) {
  margin-top: 16px;
}
@media only screen and (min-width: 1024px) {
  .app-sponsor__list > li:nth-of-type(1n + 4) {
    margin-top: 0;
  }
}

.app-sponsor__link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.app-sponsor__link > *:only-child {
  margin-top: auto;
}
@media only screen and (min-width: 1024px) {
  .app-sponsor__link {
    justify-content: initial;
  }
}

.app-sponsor__image {
  max-height: 75px;
}

.c-search {
  line-height: 1.3;
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
}

.c-search--background {
  background-color: #eee;
  margin: 0 auto;
  padding: 32px 16px;
}

.c-search--max-width {
  max-width: 720px;
}

.c-search__field {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.c-search__input-container {
  flex: 1 0 100%;
  margin-bottom: 8px;
}

@media only screen and (min-width: 540px) {
  .c-search__input-container--sm {
    flex: 999 1 auto;
    margin-right: 16px;
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .c-search__input-container--md {
    flex: 999 1 auto;
    margin-right: 16px;
    margin-bottom: 0px;
  }
}

.c-search__input-label {
  display: block;
  padding-left: 0px;
  margin-bottom: 8px;
}

.c-search__select-container {
  flex: 1 1 auto;
  margin-bottom: 8px;
}
@media only screen and (min-width: 540px) {
  .c-search__select-container {
    flex: 999 1 auto;
    margin-bottom: 0;
    margin-right: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .c-search__select-container {
    flex: 1 0 auto;
  }
}

.c-search__button-container {
  flex: 1 0 100%;
}
@media only screen and (min-width: 540px) {
  .c-search__button-container {
    flex: 1 0 auto;
  }
}

.c-search__button-container + * {
  padding-top: 8px;
}
@media only screen and (min-width: 540px) {
  .c-search__button-container + * {
    margin-left: 16px;
    padding-top: 0;
  }
}

.c-search__button-container:focus-within {
  outline: 3px solid #fece3e;
}

.c-search__input,
.c-search__select,
.c-search__button {
  padding: 0.6em 1em;
  border: 1px solid;
  border-radius: 3px;
  font-size: 1rem;
}

.c-search__input,
.c-search__select {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
}

.c-search__select {
  display: block;
  width: 100%;
  min-width: 150px;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  margin: 0;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m5.58578644 3-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l4 4c.39052429.39052429.39052429 1.02368927 0 1.41421356l-4 4c-.39052429.39052429-1.02368927.39052429-1.41421356 0s-.39052429-1.02368927 0-1.41421356z' fill='%23333' fill-rule='evenodd' transform='matrix(0 1 -1 0 11 3)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%;
  /* icon size, then gradient */
  background-size: 1em auto;
  /* Hide arrow icon in IE browsers */
}
.c-search__select::-ms-expand {
  display: none;
}

.c-search__select--width-auto {
  width: auto;
}

.c-search__button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #006699;
  background-color: #006699;
  color: white;
  width: 100%;
}
.c-search__button:hover {
  background-color: white;
  color: #006699;
}
.c-search__button svg {
  fill: currentColor;
}

.c-search__button--width-auto {
  width: auto;
}

.c-search__button-text {
  margin-right: 8px;
}

@media only screen and (min-width: 540px) {
  .c-search__button-text--hide-at-sm {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}

.c-search__link {
  font-size: 0.875rem;
  text-decoration: underline;
}
.c-search__link:hover {
  text-decoration: none;
}

.c-video-provider {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.c-video-provider iframe {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.c-video-provider__preview-button {
  border-width: 0;
}

.c-video-provider-grid--auto-fill {
  display: flex; /* no CSS grid fallback style */
  flex-wrap: wrap; /* no CSS grid fallback style */
}

.c-video-provider-grid--auto-fill > * {
  width: 270px; /* no CSS grid fallback style */
  flex: 0 1 auto; /* no CSS grid fallback style */
  margin-right: 30px; /* no CSS grid fallback style */
}

@supports (display: grid) {
  .c-video-provider-grid--auto-fill {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--column-width, 270px), 1fr));
    grid-column-gap: var(--column-gap, 30px);
    grid-row-gap: var(--row-gap, 15px);
  }
  /* remove fallback style */
  .c-video-provider-grid--auto-fill > * {
    width: auto;
    margin-right: 0;
  }
}
.c-video-provider-grid--two-or-4 {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--column-gap, 30px);
  grid-row-gap: var(--row-gap, 15px);
}

@media (min-width: 615px) {
  .c-video-provider-grid--two-or-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.c-site-messages--nature-briefing-email-variant {
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
}
.c-site-messages--nature-briefing-email-variant .visually-hidden,
.c-site-messages--nature-briefing-email-variant .js .js-visually-hidden {
  width: 1px;
  height: 1px;
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}
.c-site-messages--nature-briefing-email-variant.serif,
.c-site-messages--nature-briefing-email-variant .serif {
  font-family: "Harding", "Palatino", "Times", "Times New Roman", serif;
}
.c-site-messages--nature-briefing-email-variant.sans-serif,
.c-site-messages--nature-briefing-email-variant .sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
}
.c-site-messages--nature-briefing-email-variant .content {
  max-width: 1280px;
  margin: 0 auto;
}
.c-site-messages--nature-briefing-email-variant .box-sizing {
  box-sizing: border-box;
}
.c-site-messages--nature-briefing-email-variant .pin-right {
  float: right;
}
.c-site-messages--nature-briefing-email-variant .text13 {
  font-size: 0.8125rem;
}
.c-site-messages--nature-briefing-email-variant .text14 {
  font-size: 0.875rem;
}
.c-site-messages--nature-briefing-email-variant .block {
  display: block;
}
.c-site-messages--nature-briefing-email-variant .strong {
  font-weight: bold;
}
.c-site-messages--nature-briefing-email-variant .tighten-line-height {
  line-height: 1.4;
}
.c-site-messages--nature-briefing-email-variant .extra-tight-line-height {
  line-height: 1.3;
}
.c-site-messages--nature-briefing-email-variant .border-gray {
  border-style: solid;
  border-width: 0;
  border-color: #999;
}
.c-site-messages--nature-briefing-email-variant .border-all-1 {
  border-width: 1px;
}
.c-site-messages--nature-briefing-email-variant .pa10 {
  padding: 10px;
}
.c-site-messages--nature-briefing-email-variant .grid {
  float: left;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.c-site-messages--nature-briefing-email-variant .grid-inline,
.c-site-messages--nature-briefing-email-variant .grid {
  margin-right: 3.2%;
}
.c-site-messages--nature-briefing-email-variant .last {
  margin-right: 0;
}
.c-site-messages--nature-briefing-email-variant .grid-1 {
  width: 5.4%;
}
.c-site-messages--nature-briefing-email-variant .grid-2 {
  width: 14%;
}
.c-site-messages--nature-briefing-email-variant .grid-3 {
  width: 22.6%;
}
.c-site-messages--nature-briefing-email-variant .grid-4 {
  width: 31.2%;
}
.c-site-messages--nature-briefing-email-variant .grid-5 {
  width: 39.8%;
}
.c-site-messages--nature-briefing-email-variant .grid-6 {
  width: 48.4%;
}
.c-site-messages--nature-briefing-email-variant .grid-7 {
  width: 57%;
}
.c-site-messages--nature-briefing-email-variant .grid-8 {
  width: 65.6%;
}
.c-site-messages--nature-briefing-email-variant .grid-9 {
  width: 74.2%;
}
.c-site-messages--nature-briefing-email-variant .grid-10 {
  width: 82.8%;
}
.c-site-messages--nature-briefing-email-variant .grid-11 {
  width: 91.4%;
}
.c-site-messages--nature-briefing-email-variant .grid-12 {
  width: 100%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-1 {
  margin-left: 8.6%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-1 {
  margin-right: 8.6%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-2 {
  margin-left: 17.2%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-2 {
  margin-right: 17.2%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-3 {
  margin-left: 25.8%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-3 {
  margin-right: 25.8%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-4 {
  margin-left: 34.4%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-4 {
  margin-right: 34.4%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-5 {
  margin-left: 43%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-5 {
  margin-right: 43%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-6 {
  margin-left: 51.6%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-6 {
  margin-right: 51.6%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-7 {
  margin-left: 60.2%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-7 {
  margin-right: 60.2%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-8 {
  margin-left: 68.8%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-8 {
  margin-right: 68.8%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-9 {
  margin-left: 77.4%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-9 {
  margin-right: 77.4%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-10 {
  margin-left: 86%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-10 {
  margin-right: 86%;
}
.c-site-messages--nature-briefing-email-variant .grid-left-11 {
  margin-left: 94.6%;
}
.c-site-messages--nature-briefing-email-variant .grid-right-11 {
  margin-right: 94.6%;
}
.c-site-messages--nature-briefing-email-variant .grid-1of4 {
  width: 25%;
}
.c-site-messages--nature-briefing-email-variant .grid-1of3 {
  width: 33.3334%;
  width: 33.3333333333%;
}
.c-site-messages--nature-briefing-email-variant .grid-1of2 {
  width: 50%;
}
.c-site-messages--nature-briefing-email-variant .grid-3of4 {
  width: 75%;
}
.c-site-messages--nature-briefing-email-variant .grid-1of5 {
  width: 20%;
}
.c-site-messages--nature-briefing-email-variant .grid-2of5 {
  width: 40%;
}
.c-site-messages--nature-briefing-email-variant .grid-3of5 {
  width: 60%;
}
.c-site-messages--nature-briefing-email-variant .grid-4of5 {
  width: 80%;
}
.c-site-messages--nature-briefing-email-variant .grid-split-margin-left {
  margin-left: 1.6%;
  padding-left: 1.6% !important;
}
.c-site-messages--nature-briefing-email-variant .grid-split-margin-right {
  margin-right: 1.6%;
  padding-right: 1.6% !important;
}

.c-site-messages--nature-briefing {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #069;
  color: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.2);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: background-color 1s ease-in-out 1.2s, color 1s ease-in-out 1s, -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out, background-color 1s ease-in-out 1.2s, color 1s ease-in-out 1s;
  transition: transform 0.6s ease-in-out, background-color 1s ease-in-out 1.2s, color 1s ease-in-out 1s, -webkit-transform 0.6s ease-in-out;
  border-bottom: none;
  z-index: 2;
}

.c-site-messages--nature-briefing .icon--inline {
  position: relative;
  border: none;
  border-radius: 0;
  padding: 10px;
}

.c-site-messages--nature-briefing .icon--inline svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
}

.c-site-messages--nature-briefing .icon--inline path {
  transition: transform 0.6s ease-in-out, fill 1s ease-in-out 1.2s, color 1s ease-in-out 1s, -webkit-transform 0.6s ease-in-out;
  fill: rgba(255, 255, 255, 0.5);
}

.c-site-messages--nature-briefing .c-site-messages__close {
  border: none;
  padding: 0;
}

.c-site-messages--nature-briefing .c-site-messages__close-container {
  width: auto;
  position: relative;
  text-align: right;
  display: inline-block;
}

.c-site-messages--nature-briefing .c-site-messages__close-container svg {
  width: 25px;
  height: 25px;
}

.c-site-messages--nature-briefing.c-site-messages .c-site-messages__close {
  background: transparent;
}

@media only screen and (min-width: 960px) {
  .c-site-messages--nature-briefing.c-site-messages .c-site-messages__close {
    margin-top: 12px;
  }
  .c-site-messages--nature-briefing-email-variant.c-site-messages .c-site-messages__banner-large .c-site-messages__close {
    margin-top: 0;
  }
}
.c-site-messages__banner-large .c-site-messages__close-container {
  width: 100%;
  float: right;
  margin-top: -5px;
}

.c-site-messages__banner-large .c-site-messages__form-container {
  float: none;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .c-site-messages__banner-large .c-site-messages__close-container {
    width: 30px;
  }
  .c-site-messages__banner-large .c-site-messages__form-container {
    float: left;
    width: calc(100% - 30px);
  }
}
.c-site-messages--nature-briefing .c-site-messages__close {
  vertical-align: top;
}

.c-site-messages__banner-small .c-site-messages__content {
  display: inline-block;
  width: 89%;
}

.c-site-messages__banner-small .c-site-messages__close-container {
  position: relative;
  text-align: right;
  display: inline-block;
  width: 10%;
}

.c-site-messages--nature-briefing .nature-briefing__link {
  text-align: center;
  text-transform: none;
  border-radius: unset;
  background-color: #fff;
  border: 1px solid #fff;
  color: #069;
  white-space: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 10px 15px;
  display: inline-block;
  margin-top: 10px;
  transition: transform 0.6s ease-in-out, border 1s ease-in-out 1s, -webkit-transform 0.6s ease-in-out;
}

@media only screen and (min-width: 960px) {
  .c-site-messages--nature-briefing .nature-briefing__link {
    margin-left: 15px;
    margin-top: 0;
  }
}
.c-site-messages--is-visible {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  background-color: #fff;
  color: #222;
}

.c-site-messages--is-visible .icon--inline path {
  fill: #069;
}

.c-site-messages--is-visible .nature-briefing__link {
  border: 1px solid #069;
}

.c-site-messages--nature-briefing .c-site-messages__close {
  color: #069;
}

@media only screen and (max-width: 700px) {
  .u-hide-on-mobile {
    display: none;
  }
}
.nature-briefing-banner__email-input {
  width: 100%;
  margin-right: 1em;
  border-color: #222;
}

.nature-briefing-banner__checkbox-label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 33px;
  max-width: 467px;
}
.nature-briefing-banner__checkbox-label:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 24px;
  height: 24px;
  border: 1px solid currentColor;
  border-radius: 0;
  background: #fff;
}
.nature-briefing-banner__checkbox-label:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 5px;
  width: 14px;
  height: 7px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: solid;
  border-width: 0 0 2px 2px;
  border-top-color: transparent;
  opacity: 0;
  background: transparent;
}

.nature-briefing-banner__checkbox-checkbox {
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 4px;
  left: -2px;
  width: 28px;
  height: 28px;
  margin: 0;
  opacity: 0;
}

.nature-briefing-banner__checkbox-checkbox:checked + .nature-briefing-banner__checkbox-label:after {
  opacity: 1;
}

.nature-briefing-banner__checkbox-checkbox:focus + .nature-briefing-banner__checkbox-label:before {
  outline: 3px solid #fece3e;
}

.nature-briefing-banner__submit-button {
  border: 1px solid #fff;
  background: #069;
  color: #fff;
  line-height: 1.2;
  font-size: 1rem;
  padding: 7px 20px;
  text-align: center;
  width: auto;
  float: left;
  transition: border 1s ease-in-out 1s;
}

.nature-briefing-banner__submit-button:focus {
  outline: 3px solid #fece3e;
}

.nature-briefing-banner__submit-button:disabled {
  background-color: #777;
  color: #fff;
}

.c-site-messages--is-visible .nature-briefing-banner__submit-button {
  border: 1px solid #069;
}

.nature-briefing-banner__checkbox-label a {
  color: #fff;
  font-weight: bold;
  transition: color 1s ease-in-out 1s;
}
.nature-briefing-banner__checkbox-label a:hover {
  text-decoration: underline;
}

.c-site-messages--is-visible .nature-briefing-banner__checkbox-label a {
  color: #222;
}

.c-site-messages__banner-large,
.c-site-messages__banner-small {
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.c-site-messages__banner-large {
  display: none;
  max-width: 1280px;
}

.c-site-messages__banner-large:before,
.c-site-messages__banner-large:after {
  content: " ";
  display: table;
}

.c-site-messages__banner-large:after {
  clear: both;
}

.c-site-messages__banner-small {
  display: block;
}

@media only screen and (min-width: 641px) {
  .c-site-messages__banner-large {
    display: block;
  }
  .c-site-messages__banner-small {
    display: none;
  }
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 {
  color: #fff;
  background-color: #29303b;
  background-repeat: no-repeat;
  background-position: right top;
  background-image: url("/static/images/icons/briefing-banner-illustration.png");
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  padding-right: 120px;
}
@media only screen and (max-width: 1040px) {
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 {
    background-position: 666px 0;
  }
}
@media only screen and (max-width: 870px) {
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 {
    background-position: calc(85% + 374px - 120px) 0;
    padding-right: 15%;
  }
}
@media only screen and (max-width: 640px) {
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 {
    background-position: calc(100% + 374px - 200px) 0;
    padding-right: 100px;
  }
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing__link {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 {
    background-image: none;
    padding-right: 0;
  }
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages__banner-small .c-site-messages__content {
    width: 100%;
  }
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages__banner-small .c-site-messages--nature-briefing__strapline {
    display: block;
    width: 89%;
    margin-top: 0;
  }
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .content {
  max-width: none;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .icon--inline path {
  transition: none;
  fill: #fff;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages__close-container {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  background: #29303b;
  border-radius: 12.5px;
  border: none;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages__close-container:hover {
  background: #fff;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages__close-container:hover .c-site-messages__close svg path {
  fill: #069;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages__banner-small .c-site-messages__close-container {
  text-align: center;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages--nature-briefing__strapline {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
  font-size: 0.875rem;
}
@media only screen and (min-width: 1040px) {
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages--nature-briefing__strapline {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .c-site-messages.c-site-messages--nature-briefing-redesign-2020 .c-site-messages--nature-briefing__strapline {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__checkbox-label:before {
  border-color: transparent;
  border-radius: 2px;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__checkbox-label:after {
  border-color: #222;
  border-top-color: transparent;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__checkbox-label a {
  font-weight: normal;
  text-decoration: underline;
  color: #fff;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__email-label {
  display: block;
  padding-left: 0;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__email-wrapper {
  margin-bottom: 10px;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__email-input {
  display: inline-block;
  float: none;
  width: 70%;
  max-width: 500px;
  border: 1px solid #fff;
  border-radius: 2px;
  padding: 8px 10px 7px;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 15px;
  line-height: 0.9375rem;
  vertical-align: top;
  margin-right: 1%;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__submit-button {
  display: inline-block;
  float: none;
  transition: none;
  background-color: #29303b;
  border-radius: 2px;
  border: 1px solid #fff;
  padding: 8px 2%;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 15px;
  line-height: 0.9375rem;
  vertical-align: top;
  width: 15%;
  min-width: 75px;
  max-width: 90px;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__submit-button:hover {
  color: #29303b;
  background-color: #fff;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing-banner__checkbox-wrapper {
  position: relative;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing__link {
  border: 1px solid #fff;
  transition: none;
  border-radius: 2px;
  background-color: #29303b;
  color: #fff;
}
.c-site-messages.c-site-messages--nature-briefing-redesign-2020 .nature-briefing__link:hover {
  color: #29303b;
  background-color: #fff;
}

.c-site-messages.c-site-messages--nature-briefing-cancer {
  border-top: 5px solid #1951a0;
}

.c-site-messages.c-site-messages--nature-briefing-transres {
  border-top: 5px solid #c6d531;
}

/**
 * App
 */
.hide {
  display: none;
}

.container-type-banner-advert,
.leaderboard-container,
.leaderboard-or-billboard-container {
  display: none;
  font-size: 0;
}
@media only screen and (min-width: 768px) {
  .container-type-banner-advert,
  .leaderboard-container,
  .leaderboard-or-billboard-container {
    display: block;
    padding: 8px 0;
  }
}

.leaderboard-container,
.leaderboard-or-billboard-container {
  background-color: #44444e;
  text-align: center;
}

.leaderboard-or-billboard-inner {
  display: inline-block;
  min-width: 728px;
  max-width: 100%;
  position: relative;
  min-height: 90px;
  line-height: 0;
  word-spacing: 0;
}

.ad-with-label::before, .ad-with-label::after {
  content: "Advertisement";
  color: #fff;
  background: #999;
  font-size: 9px;
  line-height: 13px;
  font-family: "arial", sans-serif;
  text-transform: uppercase;
  position: absolute;
  word-spacing: 0;
  max-height: 100%;
  top: 0;
  bottom: 0;
}
.ad-with-label::before {
  border-right: 1px solid #fff;
  left: -15px;
  writing-mode: vertical-rl;
}
.ad-with-label::after {
  border-left: 1px solid #fff;
  right: -14px;
  writing-mode: vertical-lr;
}

.native-ad {
  font-family: "Georgia";
}

.native-ad__title {
  font-family: "Georgia";
  font-weight: 400;
}

/*
Overrides for the SN Profile user account widget. Oscar makes a call to the SN Profile account widget endpoint, which returns the html, css and js for the widget.
Applications such as ours need to override the default styling of the widget to ensure correct for our brand.
Documentation for the SN Profile account widget: https://integration-example-app-qa.springernature.com/account-widget
*/
.c-account-nav__menu {
  background-color: #000 !important;
  border-bottom: 1px solid rgb(46.75, 46.75, 46.75) !important;
  color: #eeeeee !important;
  line-height: 1.2 !important;
  padding: 16px !important;
}

@media only screen and (max-width: 539px) {
  .c-account-nav {
    position: initial !important;
  }
  .c-account-nav__menu {
    max-width: initial !important;
    width: 100% !important;
    left: 0 !important;
  }
}
.c-account-nav__menu-header {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  border-color: rgb(212.5, 212.5, 212.5) !important;
  padding: 8px 8px 16px !important;
  margin: 0 calc(8px * -1) !important;
}

.c-account-nav__anchor.is-open::after {
  content: url("data:image/svg+xml,%3Csvg role='img' padding='3px 0' aria-hidden='true' focusable='false' height='17' viewBox='0 0 17 17' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23069' d='m5.58578644 3-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l4 4c.39052429.39052429.39052429 1.02368927 0 1.41421356l-4 4c-.39052429.39052429-1.02368927.39052429-1.41421356 0s-.39052429-1.02368927 0-1.41421356z' transform='matrix(0 1 -1 0 11 3)'%3E%3C/path%3E%3C/svg%3E") !important;
}

.c-account-nav__menu-list {
  margin: 0 calc(8px * -1) !important;
}

.c-account-nav__menu-item {
  padding: 8px !important;
}

.c-account-nav__menu-item a {
  color: inherit;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}
.c-account-nav__menu-item a.visited, .c-account-nav__menu-item a:visited {
  color: inherit;
}
.c-account-nav__menu-item a.hover, .c-account-nav__menu-item a:hover {
  color: inherit;
}

.c-account-nav__menu-item a.is-open {
  color: #006699 !important;
  background-color: #eeeeee !important;
  border-radius: 2px !important;
  text-decoration: none !important;
}

.c-account-nav__menu-item:first-of-type {
  padding-top: 16px !important;
}

.c-account-nav__menu-item:last-of-type {
  padding: 8px !important;
}

.c-card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.875rem;
  padding: 0;
  border: none;
  box-shadow: none;
  overflow: hidden;
  color: inherit;
}

.c-card,
.c-card__layout,
.c-card__body {
  gap: 8px;
}

.c-card__layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
}

.c-card__body {
  padding: 16px;
  flex: 1 1 auto;
}

.c-card__body .c-author-list {
  margin-top: auto;
}

.c-card__title {
  margin: 0;
  letter-spacing: -0.01875rem;
}

.c-card__summary {
  line-height: 1.4;
}
.c-card__summary > p {
  margin-top: 0;
  margin-bottom: 4px;
}
.c-card__summary > p:last-child {
  margin-bottom: 0;
}

.c-card__image img {
  width: 100%;
}

.c-card__link:not(.c-card__link--no-block-link)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.c-card--flush .c-card__body {
  padding: 0;
}

.c-card--major {
  font-size: 1rem;
}
.c-card--dark {
  background-color: #29303c;
  color: #e3e4e5;
  border-width: 0;
}
.c-card--dark .c-card__title {
  color: white;
}

/*
------------
featured row
------------
*/
.app-featured-row {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-featured-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-featured-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-featured-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-featured-row__item .c-card__image picture {
  width: 100%;
}
.app-featured-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}

.app-featured-row__item--current-issue {
  padding-top: 16px;
  background-color: #eeeeee;
}
@media only screen and (max-width: 1219px) {
  .app-featured-row__item--current-issue {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .app-featured-row__item--current-issue {
    padding-top: 0;
    background-color: transparent;
  }
}
.app-featured-row__item--current-issue .c-card {
  max-width: 250px;
  margin: auto;
}

@media only screen and (min-width: 1024px) {
  .app-featured-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-featured-row {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 1023px) {
  .app-featured-row__item:not(.app-featured-row__item--current-issue) .c-card {
    flex-direction: column;
  }
  .app-featured-row__item:not(.app-featured-row__item--current-issue) .c-card__image {
    flex: 1 1 40%;
    max-width: 230px;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 1024px) {
  .app-featured-row__item:not(.app-featured-row__item--current-issue) .c-card {
    flex-direction: row-reverse;
  }
  .app-featured-row__item:not(.app-featured-row__item--current-issue) .c-card__layout {
    gap: 24px;
  }
  .app-featured-row__item:not(.app-featured-row__item--current-issue) .c-card .c-meta {
    flex: 0 0 230px;
  }
}
@media only screen and (max-width: 1023px) {
  .app-featured-row__item:not(.app-featured-row__item--current-issue) .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .app-featured-row__item:not(.app-featured-row__item--current-issue) .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 1024px) {
  .app-featured-row__item:not(.app-featured-row__item--current-issue) .c-card__layout {
    flex-direction: unset;
  }
}

/*
------------
news row
------------
*/
.app-news-row {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-news-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-news-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-news-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-news-row__item .c-card__image picture {
  width: 100%;
}
.app-news-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}
.app-news-row__item .c-card__image {
  align-self: flex-start;
}
.app-news-row__item .c-meta {
  margin-top: auto;
}

@media only screen and (min-width: 768px) {
  .app-news-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-news-row {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.app-news-row__item:not(.app-news-row__item--major) .c-card__layout {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 768px) {
  .app-news-row__item:not(.app-news-row__item--major) .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1024px) {
  .app-news-row__item:not(.app-news-row__item--major) .c-card__layout {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .app-news-row__item:not(.app-news-row__item--major) .c-card {
    flex-direction: column;
  }
  .app-news-row__item:not(.app-news-row__item--major) .c-card__image {
    flex: 1 1 40%;
    max-width: 230px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 1024px) {
  .app-news-row__item:not(.app-news-row__item--major) .c-card {
    flex-direction: row-reverse;
  }
  .app-news-row__item:not(.app-news-row__item--major) .c-card__layout {
    gap: 24px;
  }
  .app-news-row__item:not(.app-news-row__item--major) .c-card .c-meta {
    flex: 0 0 230px;
  }
}

.app-news-row__item--major .c-card__layout {
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .app-news-row__item--major .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1024px) {
  .app-news-row__item--major .c-card__layout {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) {
  .app-news-row__item--major {
    grid-column: 1/-1;
  }
}
@media only screen and (min-width: 1024px) {
  .app-news-row__item--major {
    grid-column: 1/3;
    grid-row: 1/-1;
    border-bottom: none;
    padding-bottom: 0;
    display: flex;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .app-news-row__item--major .c-card {
    flex-direction: column;
  }
  .app-news-row__item--major .c-card__image {
    flex: 1 0 50%;
    max-width: 60%;
    overflow: hidden;
    min-height: 260px;
  }
  .app-news-row__item--major .c-card__image img {
    object-fit: cover;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) and (min-width: 1024px) {
  .app-news-row__item--major .c-card {
    flex-direction: row-reverse;
  }
  .app-news-row__item--major .c-card__layout {
    gap: 24px;
  }
  .app-news-row__item--major .c-card .c-meta {
    flex: 0 0 230px;
  }
}
.app-news-row__item--major .c-card__image {
  align-self: auto;
}
.app-news-row__item--major .c-card__image img {
  border: none;
}

/*
------------
three item row
------------
*/
.app-three-item-row {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-three-item-row > * {
  max-width: none !important;
}

.app-three-item-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-three-item-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-three-item-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-three-item-row__item .c-card__image picture {
  width: 100%;
}
.app-three-item-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}

@media only screen and (min-width: 768px) {
  .app-three-item-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .app-three-item-row__item .c-card {
    flex-direction: column;
  }
  .app-three-item-row__item .c-card__image {
    flex: 1 1 40%;
    max-width: 230px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 1024px) {
  .app-three-item-row__item .c-card {
    flex-direction: row-reverse;
  }
  .app-three-item-row__item .c-card__layout {
    gap: 24px;
  }
  .app-three-item-row__item .c-card .c-meta {
    flex: 0 0 230px;
  }
}

/*
------------
reviews row
------------
*/
.app-reviews-row__grid {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-reviews-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-reviews-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-reviews-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-reviews-row__item .c-card__image picture {
  width: 100%;
}
.app-reviews-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}

.app-reviews-row__side {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-reviews-row__side-item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-reviews-row__side-item .c-card__summary {
  font-size: 0.875rem;
}
.app-reviews-row__side-item .c-card__image {
  aspect-ratio: auto;
}
.app-reviews-row__side-item .c-card__image picture {
  width: 100%;
}
.app-reviews-row__side-item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}

@media only screen and (min-width: 1024px) {
  .app-reviews-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .app-reviews-row__main {
    grid-column: 1/5;
  }
}

@media only screen and (min-width: 1024px) {
  .app-reviews-row__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .app-reviews-row__item:not(.app-reviews-row__item--major) .c-card {
    flex-direction: column;
  }
  .app-reviews-row__item:not(.app-reviews-row__item--major) .c-card__image {
    flex: 1 1 40%;
    max-width: 230px;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 1024px) {
  .app-reviews-row__item:not(.app-reviews-row__item--major) .c-card {
    flex-direction: row-reverse;
  }
  .app-reviews-row__item:not(.app-reviews-row__item--major) .c-card__layout {
    gap: 24px;
  }
  .app-reviews-row__item:not(.app-reviews-row__item--major) .c-card .c-meta {
    flex: 0 0 230px;
  }
}
@media only screen and (max-width: 1023px) {
  .app-reviews-row__item:not(.app-reviews-row__item--major) .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .app-reviews-row__item:not(.app-reviews-row__item--major) .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 1024px) {
  .app-reviews-row__item:not(.app-reviews-row__item--major) .c-card__layout {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .app-reviews-row__item--major {
    grid-column: 1/-1;
  }
  .app-reviews-row__item--major .c-card {
    flex-direction: column;
  }
  .app-reviews-row__item--major .c-card__image {
    flex: 1 0 50%;
    max-width: 60%;
    overflow: hidden;
    min-height: 260px;
  }
  .app-reviews-row__item--major .c-card__image img {
    object-fit: cover;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1024px) {
  .app-reviews-row__item--major .c-card {
    flex-direction: row-reverse;
  }
  .app-reviews-row__item--major .c-card__layout {
    gap: 24px;
  }
  .app-reviews-row__item--major .c-card .c-meta {
    flex: 0 0 230px;
  }
}
@media only screen and (min-width: 768px) {
  .app-reviews-row__item--major .c-card__layout {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .app-reviews-row__item--major .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1024px) {
  .app-reviews-row__item--major .c-card__layout {
    flex-direction: row-reverse;
  }
}

@media only screen and (min-width: 1024px) {
  .app-reviews-row__side {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1024px) {
  .app-reviews-row__side-item {
    flex: 1;
  }
}

.app-reviews-row__item:not(.app-reviews-row__item--major) .c-meta,
.app-reviews-row__item--major .c-meta,
.app-reviews-row__side-item .c-meta {
  margin-top: auto;
}

/*
------------
article list row
------------
*/
.app-article-list-row {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-article-list-row__mpu {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 540px) {
  .app-article-list-row__mpu {
    flex-wrap: nowrap;
  }
}
.app-article-list-row__mpu > .c-card {
  flex: 1 1 auto;
}
@media only screen and (min-width: 540px) {
  .app-article-list-row__mpu > .c-card {
    margin-right: 16px;
  }
}
.app-article-list-row__mpu > .c-ad.c-ad--300x250 {
  padding-top: 16px;
}
@media only screen and (min-width: 540px) {
  .app-article-list-row__mpu > .c-ad.c-ad--300x250 {
    padding-top: 0;
  }
}

.app-article-list-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-article-list-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-article-list-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-article-list-row__item .c-card__image picture {
  width: 100%;
}
.app-article-list-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}
.app-article-list-row__item .c-card__layout {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 768px) {
  .app-article-list-row__item .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1024px) {
  .app-article-list-row__item .c-card__layout {
    flex-direction: row-reverse;
  }
}
.app-article-list-row__item .c-card {
  flex-direction: column;
}
.app-article-list-row__item .c-card__image {
  flex: 1 1 40%;
  max-width: 230px;
}
@media only screen and (min-width: 1024px) {
  .app-article-list-row__item .c-card {
    flex-direction: row-reverse;
  }
  .app-article-list-row__item .c-card__layout {
    gap: 24px;
  }
  .app-article-list-row__item .c-card .c-meta {
    flex: 0 0 230px;
  }
}

/*
------------
announcement list row
------------
*/
.app-announcement-list-row {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-announcement-list-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-announcement-list-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-announcement-list-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-announcement-list-row__item .c-card__image picture {
  width: 100%;
}
.app-announcement-list-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}
.app-announcement-list-row__item .c-card__layout {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 768px) {
  .app-announcement-list-row__item .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1024px) {
  .app-announcement-list-row__item .c-card__layout {
    flex-direction: row-reverse;
  }
}
.app-announcement-list-row__item .c-card {
  flex-direction: column;
}
.app-announcement-list-row__item .c-card__image {
  flex: 1 1 40%;
  max-width: 230px;
}
@media only screen and (min-width: 1024px) {
  .app-announcement-list-row__item .c-card {
    flex-direction: row-reverse;
  }
  .app-announcement-list-row__item .c-card__layout {
    gap: 24px;
  }
  .app-announcement-list-row__item .c-card .c-meta {
    flex: 0 0 230px;
  }
}
.app-announcement-list-row__item .c-card__image {
  max-width: 300px;
}
.app-announcement-list-row__item .c-card__title {
  max-width: 750px;
}
.app-announcement-list-row__item .c-card__summary {
  max-width: 750px;
  font-size: 1rem;
}

/*
---------------
collections row
---------------
*/
.app-collections-row {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 1024px) {
  .app-collections-row {
    display: flex;
  }
}

.app-collections-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-collections-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-collections-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-collections-row__item .c-card__image picture {
  width: 100%;
}
.app-collections-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}
@media only screen and (min-width: 768px) {
  .app-collections-row__item .c-card {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1024px) {
  .app-collections-row__item .c-card {
    flex-direction: row-reverse;
  }
  .app-collections-row__item .c-card__layout {
    gap: 24px;
  }
  .app-collections-row__item .c-card .c-meta {
    flex: 0 0 230px;
  }
}
@media only screen and (min-width: 768px) {
  .app-collections-row__item .c-card__layout {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .app-collections-row__item .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1024px) {
  .app-collections-row__item .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1024px) {
  .app-collections-row__item {
    margin-right: 16px;
    flex: 1 1 auto;
  }
}
@media only screen and (max-width: 1023px) {
  .app-collections-row__item {
    border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .app-collections-row__item .c-card__image {
    flex: 1 1 auto;
  }
  .app-collections-row__item .c-card__image > img {
    max-height: 250px;
  }
}
.app-collections-row__item .c-meta {
  margin-top: auto;
}

/*
------------
native ad row
------------
*/
.app-native-ad {
  font-family: "Georgia";
}
.app-native-ad .c-card {
  flex-direction: column;
}
.app-native-ad .c-card__image {
  flex: 1 1 40%;
  max-width: 230px;
}
@media only screen and (min-width: 1024px) {
  .app-native-ad .c-card {
    flex-direction: row-reverse;
  }
  .app-native-ad .c-card__layout {
    gap: 24px;
  }
  .app-native-ad .c-card .c-meta {
    flex: 0 0 230px;
  }
}
.app-native-ad .c-card__layout {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 768px) {
  .app-native-ad .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1024px) {
  .app-native-ad .c-card__layout {
    flex-direction: row-reverse;
  }
}

.app-native-ad__content {
  border-top: 1px solid rgb(212.5, 212.5, 212.5);
  padding-top: 16px;
  margin-top: 16px;
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-native-ad__content .c-card__summary {
  font-size: 0.875rem;
}
.app-native-ad__content .c-card__image {
  aspect-ratio: auto;
}
.app-native-ad__content .c-card__image picture {
  width: 100%;
}
.app-native-ad__content .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}

/*
------------
trending row
------------
*/
.app-trending-row {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .app-trending-row {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
  }
  .app-trending-row .app-trending-row__item {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .app-trending-row {
    grid-template-columns: repeat(4, 1fr);
  }
}

.app-trending-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .app-trending-row__item:last-child {
    margin-bottom: 0;
  }
}

.app-trending-row__link {
  color: inherit;
  display: block;
}
.app-trending-row__link.visited, .app-trending-row__link:visited {
  color: inherit;
}
.app-trending-row__link.hover, .app-trending-row__link:hover {
  color: inherit;
}

/*
------------
events row
------------
*/
@media only screen and (max-width: 767px) {
  .app-careers-row {
    border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-careers-row {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  .app-careers-row__main {
    display: flex;
  }
}
@media only screen and (min-width: 1024px) {
  .app-careers-row__main {
    padding-right: 16px;
    flex-grow: 1;
  }
}

.app-careers-row__block {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .app-careers-row__block {
    flex: 1 1 100%;
  }
}
@media only screen and (min-width: 768px) {
  .app-careers-row__block:first-child {
    padding-right: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .app-careers-row__block:only-child {
    padding-right: 0;
  }
}

.app-careers-row__listing {
  flex-grow: 1;
}
.app-careers-row__listing .c-list-group__item h4 {
  line-height: 1.5;
}

/*
------------
subjects row
------------
*/
.app-subjects-row {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-subjects-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-subjects-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-subjects-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-subjects-row__item .c-card__image picture {
  width: 100%;
}
.app-subjects-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}

@media only screen and (min-width: 768px) {
  .app-subjects-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-subjects-row {
    grid-template-columns: repeat(5, 1fr);
  }
}

.app-subjects-row__item .c-card__layout {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 768px) {
  .app-subjects-row__item .c-card__layout {
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 1024px) {
  .app-subjects-row__item .c-card__layout {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .app-subjects-row__item .c-card {
    flex-direction: column;
  }
  .app-subjects-row__item .c-card__image {
    flex: 1 1 40%;
    max-width: 230px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 1024px) {
  .app-subjects-row__item .c-card {
    flex-direction: row-reverse;
  }
  .app-subjects-row__item .c-card__layout {
    gap: 24px;
  }
  .app-subjects-row__item .c-card .c-meta {
    flex: 0 0 230px;
  }
}

/*
------------
sponsor row
------------
*/
.app-sponsor-row {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.app-sponsor-row__item {
  width: 100%;
  margin-bottom: 48px;
  text-align: center;
  padding: 0 32px;
}

.app-sponsor-row__link {
  color: #626262;
  display: block;
}

.app-sponsor-row__label {
  font-size: 0.875rem;
  line-height: 1.25;
  margin: 0 0 16px;
}

.app-sponsor-row__img {
  max-width: 180px;
  max-height: 78px;
}

.app-sponsor-row__item:only-child {
  width: 100%;
}
.app-sponsor-row__item:only-child .app-sponsor-row__img {
  max-width: 300px;
  max-height: 130px;
}

@media only screen and (min-width: 540px) {
  .app-sponsor-row__item {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .app-sponsor-row__item {
    width: 33.3333333333%;
  }
}
@media only screen and (min-width: 1024px) {
  .app-sponsor-row__item {
    width: 25%;
  }
}
/*
------------
membership row
------------
*/
.app-membership-row {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #222222;
  margin-top: 16px;
  padding-top: 16px;
}

.app-membership-row__item {
  flex: 1 1 50%;
  color: rgb(97.75, 97.75, 97.75);
}

/*
------------
MPU in a row
------------
*/
.app-careers-row > .c-ad.c-ad--300x250,
.app-article-list-row__mpu > .c-ad.c-ad--300x250,
.app-collections-row > .c-ad.c-ad--300x250 {
  flex: 1 0 300px;
}

.app-latest-issue-row {
  padding: 16px;
  background-color: #eeeeee;
  margin: 0 auto 30px auto;
}
@media only screen and (min-width: 768px) {
  .app-latest-issue-row {
    display: flex;
  }
}
.app-latest-issue-row .c-author-list {
  color: #555555;
}

.app-latest-issue-row__article-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .app-latest-issue-row__main {
    flex: 1 1 auto;
    padding-right: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .app-latest-issue-row__side {
    flex: 0 0 440px;
    max-width: 440px;
  }
}
.app-latest-issue-row__side img {
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .app-latest-issue-row__side img {
    display: block;
    margin: 0 auto;
  }
}

.app-latest-issue-row__buttons {
  margin-top: 16px;
}
@media only screen and (min-width: 1024px) {
  .app-latest-issue-row__buttons {
    display: flex;
    justify-content: space-between;
  }
}

.app-latest-issue-row__item {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.app-latest-issue-row__item .c-card__summary {
  font-size: 0.875rem;
}
.app-latest-issue-row__item .c-card__image {
  aspect-ratio: auto;
}
.app-latest-issue-row__item .c-card__image picture {
  width: 100%;
}
.app-latest-issue-row__item .c-card__image img {
  border: 1px solid rgb(212.5, 212.5, 212.5);
  height: auto;
}

.app-latest-issue-row__button {
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .app-latest-issue-row__button {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0px;
  }
  .app-latest-issue-row__button:not(:only-child):not(:last-child) {
    margin-right: 8px;
  }
}

.app-latest-issue-row__image {
  box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.5);
}

@media only screen and (min-width: 1024px) {
  .app-latest-issue-row--by-3 .app-latest-issue-row__button {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

.app-primeview {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
  padding: 8px;
  transition: 0.25s ease, background-color 0.25s ease;
}
.app-primeview:hover {
  background-color: rgb(212.5, 212.5, 212.5);
}

.app-primeview__link {
  text-decoration: underline;
}
.app-primeview__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.app-primeview__main {
  flex: 1 1 auto;
  min-width: 1px;
}

.app-primeview__side {
  flex: 0 0 120px;
  max-width: 120px;
}

.c-header__item--snid-account-widget {
  display: flex;
}

.c-header__item--snid-account-widget .c-header__link--notifications {
  display: none;
}

@media only screen and (min-width: 540px) {
  .c-header__item--snid-account-widget .c-header__link--notifications {
    display: inline-block;
  }
}
.c-header__item--snid-account-widget .c-header__link--notifications {
  position: relative;
  margin-right: 10px;
}

.c-header__item--snid-account-widget .c-header__notification-count {
  top: 0;
  right: -18px;
}

@media only screen and (min-width: 540px) {
  .c-header__item--snid-account-widget .c-header__link--notifications + .c-account-nav {
    margin-left: 8px;
  }
}
.c-header__item--snid-account-widget .c-account-nav__anchor.eds-c-header__link::after {
  margin: 0;
  line-height: 1;
  height: 17px;
  content: url("data:image/svg+xml,%3Csvg role='img' padding='3px 0' aria-hidden='true' focusable='false' height='17' viewBox='0 0 17 17' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23222' d='m5.58578644 3-3.29289322-3.29289322c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356s1.02368927-.39052429 1.41421356 0l4 4c.39052429.39052429.39052429 1.02368927 0 1.41421356l-4 4c-.39052429.39052429-1.02368927.39052429-1.41421356 0s-.39052429-1.02368927 0-1.41421356z' transform='matrix(0 1 -1 0 11 3)'%3E%3C/path%3E%3C/svg%3E");
  transform: rotate(0deg);
}

.c-header__item--snid-account-widget .c-account-nav__anchor.is-open::after {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg) translateZ(0);
}

.c-header__container {
  padding: 0 4px;
}

.c-header__list {
  padding: 0 12px;
}

.c-header__menu .c-header__link {
  font-size: 14px;
}

.c-header__item--snid-account-widget .c-header__link {
  padding: 8px;
}

.c-header__menu--journal {
  margin-left: 0;
}

@media only screen and (min-width: 540px) {
  .c-header__container {
    padding: 0 16px;
  }
  .c-header__menu--journal {
    margin-left: -8px;
  }
  .c-header__menu .c-header__link {
    font-size: 16px;
  }
  .c-header__link--search {
    gap: 13px;
  }
}
.c-collection-editors {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-collection-editors__ampersand,
.c-collection-editors__comma {
  display: none;
}

.js .c-collection-editors__item,
.js .c-collection-editors__ampersand,
.js .c-collection-editors__comma {
  display: inline-block;
}

.js .c-collection-editors__name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;
}

.c-collection-editors__name a {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  text-decoration: underline;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.08em;
}
.c-collection-editors__name a:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.3875rem;
  text-underline-offset: 0.08em;
}

.c-collection-editor__popup h3 {
  display: none;
}

.c-collection-editors__image {
  margin-right: 16px;
  margin-bottom: 8px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.js .c-popup.c-collection-editor__popup {
  font-size: 1rem;
}

.js .c-collection-editor__popup h3 {
  display: block;
  margin-bottom: 8px;
}

.js .c-collection-editor__popup p {
  margin: 0;
  white-space: pre-line;
}

/**
 * Utilities
 */
/**
 * Button utilities
 * These are for styled branded buttons
 */
.u-button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #006699;
  background-color: transparent;
  background-image: none;
  border: 1px solid #006699;
}
.u-button svg {
  fill: currentColor;
}
.u-button:visited {
  color: #006699;
}
.u-button:hover {
  border: 1px solid #006699;
}
.u-button:focus {
  border: 1px solid #006699;
}
.u-button:hover, .u-button:focus {
  color: white;
  background-color: #006699;
  background-image: none;
}
.u-button:hover svg path, .u-button:focus svg path {
  fill: white;
}

.u-button--primary {
  color: white;
  background-color: #006699;
  background-image: none;
  border: 1px solid #006699;
}
.u-button--primary svg {
  fill: currentColor;
}
.u-button--primary:visited {
  color: white;
}
.u-button--primary:hover {
  border: 1px solid #006699;
}
.u-button--primary:focus {
  border: 1px solid #006699;
}
.u-button--primary:hover, .u-button--primary:focus {
  color: #006699;
  background-color: white;
  background-image: none;
}
.u-button--primary:hover svg path, .u-button--primary:focus svg path {
  fill: #006699;
}

.u-button--contrast {
  color: white;
  background-color: transparent;
  background-image: none;
  border: 1px solid white;
  font-weight: normal;
}
.u-button--contrast svg {
  fill: currentColor;
}
.u-button--contrast:visited {
  color: white;
}
.u-button--contrast:hover {
  border: 1px solid white;
}
.u-button--contrast:focus {
  border: 1px solid white;
}
.u-button--contrast:hover, .u-button--contrast:focus {
  color: black;
  background-color: white;
  background-image: none;
}
.u-button--contrast:hover svg path, .u-button--contrast:focus svg path {
  fill: black;
}

.u-button--disabled,
.u-button:disabled {
  color: #222222;
  background-color: transparent;
  background-image: none;
  border: 1px solid #eeeeee;
  opacity: 0.7;
  cursor: default;
}
.u-button--disabled svg,
.u-button:disabled svg {
  fill: currentColor;
}
.u-button--disabled:visited,
.u-button:disabled:visited {
  color: #222222;
}
.u-button--disabled:hover,
.u-button:disabled:hover {
  border: 1px solid #eeeeee;
  text-decoration: none;
}
.u-button--disabled:focus,
.u-button:disabled:focus {
  border: 1px solid #eeeeee;
  text-decoration: none;
}
.u-button--disabled:hover, .u-button--disabled:focus,
.u-button:disabled:hover,
.u-button:disabled:focus {
  color: #222222;
  background-color: transparent;
  background-image: none;
}
.u-button--disabled:hover svg path, .u-button--disabled:focus svg path,
.u-button:disabled:hover svg path,
.u-button:disabled:focus svg path {
  fill: #222222;
}

.u-button--xsmall {
  font-size: 0.875rem;
  padding: 2px;
}

.u-button--small {
  font-size: 0.875rem;
  padding: inherit;
}

.u-button--large {
  font-size: 1.125rem;
  padding: 16px;
}

.u-button--full-width {
  display: flex;
  width: 100%;
}

.u-button--icon-left svg {
  margin-right: 8px;
}

.u-button--icon-right svg {
  margin-left: 8px;
}

.u-clearfix::before, .u-clearfix::after {
  content: "";
  display: table;
}
.u-clearfix::after {
  clear: both;
}

.u-color-open-access {
  color: #b74616;
}

.u-color-inherit {
  color: inherit;
}

.u-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.u-display-block {
  display: block;
}

.u-display-inline {
  display: inline;
}

.u-display-inline-block {
  display: inline-block;
}

.u-display-flex {
  display: flex;
  width: 100%;
}

.u-display-inline-flex {
  display: inline-flex;
}

.u-display-grid {
  display: grid;
}

.u-display-table {
  display: table;
}

.u-flex-direction-row {
  flex-direction: row;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-baseline {
  align-items: baseline;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-left {
  justify-content: left;
}

.u-flex-grow {
  flex: 1 0 auto;
}

.u-flex-grow-shrink {
  flex: 1 1 auto;
}

.u-flex-shrink {
  flex: 0 1 auto;
}

.u-flex-static {
  flex: 0 0 auto;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-start {
  align-self: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-display-none {
  display: none;
}

/* hide from both screenreaders and browsers */
.u-hide,
.js .u-js-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + *,
.js .u-js-hide:first-child + * {
  margin-block-start: 0;
}

/* show to both screenreaders and browsers */
.u-show,
.js .u-js-show {
  display: block;
  visibility: visible;
}

.u-show-inline,
.js .u-js-show-inline {
  display: inline;
  visibility: visible;
}

/* hide only visually, but have it available for screenreaders */
.u-visually-hidden,
.js .u-js-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.u-visually-hidden--off,
.js .u-js-visually-hidden--off {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}

/* make invisible but retain dimensions */
.u-invisible {
  visibility: hidden;
}

/* hide only the text, keep element visible */
.u-hide-text,
.js .u-js-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  vertical-align: bottom;
}

/* hiding overflow */
.u-hide-overflow {
  overflow: hidden;
}

@media print {
  .u-hide-print {
    display: none;
  }
}
/**
 * media-query hiding
 * intentionally avoids 'max' and 'range' to prevent bloat
 */
@media only screen and (min-width: 320px) {
  .u-hide-at-xs,
  .js .u-js-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + *,
  .js .u-js-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xs,
  .js .u-js-show-at-xs {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 540px) {
  .u-hide-at-sm,
  .js .u-js-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + *,
  .js .u-js-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-sm,
  .js .u-js-show-at-sm {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-md,
  .js .u-js-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + *,
  .js .u-js-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-md,
  .js .u-js-show-at-md {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg,
  .js .u-js-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + *,
  .js .u-js-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-lg,
  .js .u-js-show-at-lg {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl,
  .js .u-js-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + *,
  .js .u-js-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xl,
  .js .u-js-show-at-xl {
    display: block;
    visibility: visible;
  }
}
.u-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
}

.u-full-height {
  height: 100%;
}

.u-vertical-align-baseline {
  vertical-align: baseline;
}

.u-vertical-align-top {
  vertical-align: top;
}

.u-vertical-align-bottom {
  vertical-align: bottom;
}

.u-vertical-align-middle {
  vertical-align: middle;
}

.u-link-inherit {
  color: inherit;
}
.u-link-inherit.visited, .u-link-inherit:visited {
  color: inherit;
}
.u-link-inherit.hover, .u-link-inherit:hover {
  color: inherit;
}

.u-link-underline {
  text-decoration: underline;
}

.u-link-faux-block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-list-style-number {
  list-style-type: decimal;
}

.u-list-style-bullet {
  list-style-type: disc;
}

.u-list-style-dash > ::marker {
  content: "– ";
  font-size: 1.2em;
  line-height: 1;
}

.u-list-style-none {
  list-style-type: none;
}

.u-list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

.u-custom-list-number {
  float: left;
  width: 3em;
  padding-right: 0.5em;
  margin-left: -3.5em;
  text-align: right;
}

.u-list-comma-separated {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-comma-separated > li {
  display: inline;
}
.u-list-comma-separated > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.u-list-comma-separated > li:not(:only-child):last-child::before {
  content: " & ";
}

.u-list-truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-visible {
  overflow: visible;
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.u-position-top {
  top: 0;
}

.u-position-left {
  left: 0;
}

.u-position-bottom {
  bottom: 0;
}

.u-position-right {
  right: 0;
}

.u-position-negative-right {
  right: 100%;
}

.u-keyline {
  border-bottom: 1px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-keyline--thick {
  border-bottom: 2px solid rgb(212.5, 212.5, 212.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-shadow {
  border: 1px solid #eee;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.u-button-reset {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.u-monospace {
  font-family: monospace;
}

.u-sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.u-serif {
  font-family: serif;
}

.u-small-caps {
  font-size: 0.8em; /* scale based on containing text */
  text-transform: uppercase;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

.u-double-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  border-bottom: 1px solid;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.u-upper {
  text-transform: uppercase;
}

.u-lower {
  text-transform: lowercase;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}

.u-unstyled {
  font-weight: normal;
  font-style: normal;
}

/**
 * Classes used to maintain the semantically appropriate heading levels
 */
@media only screen and (max-width: 539px) {
  .u-hide-sm-max {
    display: none;
    visibility: hidden;
  }
  .u-hide-sm-max:first-child + * {
    margin-block-start: 0;
  }
}

.u-show-lg {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .u-show-lg {
    display: block;
  }
}

.u-ma-0 {
  margin: 0;
}

.u-ma-2 {
  margin: 2px;
}

.u-ma-4 {
  margin: 4px;
}

.u-ma-8 {
  margin: 8px;
}

.u-ma-16 {
  margin: 16px;
}

.u-ma-24 {
  margin: 24px;
}

.u-ma-32 {
  margin: 32px;
}

.u-ma-48 {
  margin: 48px;
}

.u-ma-64 {
  margin: 64px;
}

.u-ma-auto {
  margin: auto;
}

.u-mt-0 {
  margin-top: 0;
}

.u-mt-2 {
  margin-top: 2px;
}

.u-mt-4 {
  margin-top: 4px;
}

.u-mt-8 {
  margin-top: 8px;
}

.u-mt-16 {
  margin-top: 16px;
}

.u-mt-24 {
  margin-top: 24px;
}

.u-mt-32 {
  margin-top: 32px;
}

.u-mt-48 {
  margin-top: 48px;
}

.u-mt-64 {
  margin-top: 64px;
}

.u-mt-auto {
  margin-top: auto;
}

.u-mr-0 {
  margin-right: 0;
}

.u-mr-2 {
  margin-right: 2px;
}

.u-mr-4 {
  margin-right: 4px;
}

.u-mr-8 {
  margin-right: 8px;
}

.u-mr-16 {
  margin-right: 16px;
}

.u-mr-24 {
  margin-right: 24px;
}

.u-mr-32 {
  margin-right: 32px;
}

.u-mr-48 {
  margin-right: 48px;
}

.u-mr-64 {
  margin-right: 64px;
}

.u-mr-auto {
  margin-right: auto;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-2 {
  margin-bottom: 2px;
}

.u-mb-4 {
  margin-bottom: 4px;
}

.u-mb-8 {
  margin-bottom: 8px;
}

.u-mb-16 {
  margin-bottom: 16px;
}

.u-mb-24 {
  margin-bottom: 24px;
}

.u-mb-32 {
  margin-bottom: 32px;
}

.u-mb-48 {
  margin-bottom: 48px;
}

.u-mb-64 {
  margin-bottom: 64px;
}

.u-mb-auto {
  margin-bottom: auto;
}

.u-ml-0 {
  margin-left: 0;
}

.u-ml-2 {
  margin-left: 2px;
}

.u-ml-4 {
  margin-left: 4px;
}

.u-ml-8 {
  margin-left: 8px;
}

.u-ml-16 {
  margin-left: 16px;
}

.u-ml-24 {
  margin-left: 24px;
}

.u-ml-32 {
  margin-left: 32px;
}

.u-ml-48 {
  margin-left: 48px;
}

.u-ml-64 {
  margin-left: 64px;
}

.u-ml-auto {
  margin-left: auto;
}

.u-pa-0 {
  padding: 0;
}

.u-pa-2 {
  padding: 2px;
}

.u-pa-4 {
  padding: 4px;
}

.u-pa-8 {
  padding: 8px;
}

.u-pa-16 {
  padding: 16px;
}

.u-pa-24 {
  padding: 24px;
}

.u-pa-32 {
  padding: 32px;
}

.u-pa-48 {
  padding: 48px;
}

.u-pa-64 {
  padding: 64px;
}

.u-pt-0 {
  padding-top: 0;
}

.u-pt-2 {
  padding-top: 2px;
}

.u-pt-4 {
  padding-top: 4px;
}

.u-pt-8 {
  padding-top: 8px;
}

.u-pt-16 {
  padding-top: 16px;
}

.u-pt-24 {
  padding-top: 24px;
}

.u-pt-32 {
  padding-top: 32px;
}

.u-pt-48 {
  padding-top: 48px;
}

.u-pt-64 {
  padding-top: 64px;
}

.u-pr-0 {
  padding-right: 0;
}

.u-pr-2 {
  padding-right: 2px;
}

.u-pr-4 {
  padding-right: 4px;
}

.u-pr-8 {
  padding-right: 8px;
}

.u-pr-16 {
  padding-right: 16px;
}

.u-pr-24 {
  padding-right: 24px;
}

.u-pr-32 {
  padding-right: 32px;
}

.u-pr-48 {
  padding-right: 48px;
}

.u-pr-64 {
  padding-right: 64px;
}

.u-pb-0 {
  padding-bottom: 0;
}

.u-pb-2 {
  padding-bottom: 2px;
}

.u-pb-4 {
  padding-bottom: 4px;
}

.u-pb-8 {
  padding-bottom: 8px;
}

.u-pb-16 {
  padding-bottom: 16px;
}

.u-pb-24 {
  padding-bottom: 24px;
}

.u-pb-32 {
  padding-bottom: 32px;
}

.u-pb-48 {
  padding-bottom: 48px;
}

.u-pb-64 {
  padding-bottom: 64px;
}

.u-pl-0 {
  padding-left: 0;
}

.u-pl-2 {
  padding-left: 2px;
}

.u-pl-4 {
  padding-left: 4px;
}

.u-pl-8 {
  padding-left: 8px;
}

.u-pl-16 {
  padding-left: 16px;
}

.u-pl-24 {
  padding-left: 24px;
}

.u-pl-32 {
  padding-left: 32px;
}

.u-pl-48 {
  padding-left: 48px;
}

.u-pl-64 {
  padding-left: 64px;
}

.grade-c-hide {
  display: block;
}